<template>
  <section>
    <Card dis-hover class="dashboard-fav-markets">
      <template slot="title">
        <div class="d-flex align-center justify-between">
        <span class="fontRegular-16">
          {{ $t("dashboard.favoriteMarkets") }}
        </span>
          <Button class="text-black border-radius-8" to="/markets" type="warning">
            <div class="d-flex align-center">
              <BaseIcon name="GrowLine" class="me-1"/>
              <span class="font-Medium-12">
              {{ $t("dashboard.allMarkets") }}
            </span>
            </div>
          </Button>
        </div>
      </template>
    </Card>

    <div class="fav-table table-responsive-wrapper">
      <Table
        :columns="columns"
        :data="marketsList"
        :disabled-hover="true"
        :height="276"
        :loading="loading"
        :no-data-text="loading ? '' : $t('common.nodata')"
        class="table-responsive-wrapper-in table-row-cursor table-border-radius"
        @on-cell-click="goHref"
      >
        <template slot="loading">
          <Loader/>
        </template>
        <template slot="symbol" slot-scope="{ row, index }">
          <div class="d-flex align-center">
            <div class="me-2">
              <icon :id="`fav_btn-${index}`" color="#f0a70c" size="14" type="ios-star"
                    @click="removeFav($event, index, row)"></icon>
            </div>
            <img v-if="row.logoUrl" :src="row.logoUrl" height="20" width="20" @error="setDefaultLogo"/>
            <img v-else :src="require('@/assets/images/coin_place_holder.png')" height="20" width="20"
                 @error="setDefaultLogo"/>
            <div class="ms-2">
              <div class="font-Regular-12">
                {{ row.displayName }}
              </div>
              <div class="font-Regular-10 gray-text">
                {{ row.symbol }}
              </div>
            </div>
          </div>
        </template>
      </Table>
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Loader from "@/components/UI/LoadingComp"
import BaseIcon from "@/components/UI/BaseIcon";

export default {
  name: "index",
  components: {
    Loader,
    BaseIcon
  },
  props: {
    marketsList: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      columns: [],
    }
  },
  mounted() {
    this.columns = [
      {
        title: this.$t("marketsPage.coin"),
        align: this.isLocaleLTR ? "left" : "right",
        minWidth: 180,
        key: "symbol",
        slot: "symbol",
        sortable: true
      },
      {
        title: this.$t("marketsPage.newPrice"),
        align: this.isLocaleLTR ? "left" : "right",
        minWidth: 120,
        key: "close",
        sortable: true,
        sortMethod: (a, b, type) => {
          let a1 = parseFloat(a);
          let b1 = parseFloat(b);
          if (type == "asc") {
            return a1 - b1;
          } else {
            return b1 - a1;
          }
        },
        render: (h, params) => {
          const isgreen =
              parseFloat(params.row.chg) < 0 ? "none" : "inline-block";
          const nogreen =
              parseFloat(params.row.chg) < 0 ? "inline-block" : "none";
          return h(
              "div",
              {
                attrs: {
                  class: "price-td",
                },
              },
              [
                h(
                    "span",
                    {},
                    this.numberWithCommas(params.row.close, this.getPairCoinScales[params.row.symbol].frontBaseCoinScale)
                ),
                h(
                    "Icon",
                    {
                      props: {
                        type: "arrow-up-c",
                      },
                      style: {
                        display: isgreen,
                        fontSize: "16px",
                        marginLeft: "5px",
                        verticalAlign: "middle",
                      },
                      class: {
                        green: true,
                      },
                    },
                    "↑"
                ),
                h(
                    "Icon",
                    {
                      props: {
                        type: "arrow-down-c",
                      },
                      style: {
                        display: nogreen,
                        fontSize: "16px",
                        marginLeft: "5px",
                        verticalAlign: "middle",
                      },
                      class: {
                        red: true,
                      },
                    },
                    "↓"
                ),
              ]
          );
        },
      },
      {
        title: this.$t("marketsPage.change"),
        align: this.isLocaleLTR ? "left" : "right",
        minWidth: 120,
        key: "chg",
        sortable: true,
        render: (h, params) => {
          const className =
              parseFloat(params.row.chg) >= 0
                  ? parseFloat(params.row.chg) === 0
                      ? ""
                      : "green"
                  : "red";
          return h(
              "div",
              {
                style: {
                  direction: "ltr",
                },
                attrs: {
                  class: className,
                },
              },
              params.row.chg > 0 ? "+" + (params.row.chg * 100).toFixed(2) + "%" : (params.row.chg * 100).toFixed(2) + "%",
          );
        },
      },
      {
        title: this.$t("marketsPage.high"),
        align: this.isLocaleLTR ? "left" : "right",
        minWidth: 120,
        key: "high",
        render: (h, params) => {
          return h(
              "div",
              {},
              this.numberWithCommas(params.row.high, this.getPairCoinScales[params.row.symbol].frontBaseCoinScale)
          );
        },
      },
      {
        title: this.$t("marketsPage.low"),
        align: this.isLocaleLTR ? "left" : "right",
        minWidth: 120,
        key: "low",
        render: (h, params) => {
          return h(
              "div",
              {},
              this.numberWithCommas(params.row.low, this.getPairCoinScales[params.row.symbol].frontBaseCoinScale)
          );
        },
      },
      {
        title: this.$t("marketsPage.operate"),
        align: "center",
        minWidth: 100,
        key: "operation",
        render: (h, params) => {
          return h("div", [
            h(
                "button",
                {
                  props: {
                    type: "text",
                    size: "small",
                  },
                  style: {
                    fontSize: "12px",
                    fontWeight: 500,
                    border: "1px solid #f0ac19",
                    color: "#f1ac19",
                    padding: "3px 10px",
                    borderRadius: "6px",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                  },
                  on: {
                    click: () => {
                      this.goHref(params.row)
                    },
                  },
                },
                this.$t("marketsPage.trading")
            ),
          ]);
        },
      },
    ]
  },
  computed: {
    ...mapGetters({
      getLoginStatus: "global/IS_LOGIN",
      getPairCoinScales: "market/GET_PAIR_COIN_SCALES",
    }),
  },
  methods: {
    ...mapActions({
      deleteFavoredMarkets: "exchange/DELETE_FAVORED_MARKET_SERVICE",
    }),
    setDefaultLogo(e) {
      e.target.src = require("@/assets/images/coin_place_holder.png");
    },
    removeFav(event, index, row) {
      event.stopPropagation();
      if (!this.getLoginStatus) {
        this.$toast.warning(this.$t("common.loginTip"));
        return;
      }
      let payload = {
        symbol: row.symbol,
      };
      this.deleteFavoredMarkets(payload)
        .then(() => {
          this.$toast.success(this.$t("marketsPage.removeFavorites"));
          this.$emit("refreshMarkets")
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
    goHref(currentRow) {
      this.$router.push(
        {
          name: "ExchangePair",
          params: {
            pair: currentRow.symbol.replace('/', '_').toLowerCase(),
          },
        },
        () => {}
      );
    },
  },
}
</script>

<style scoped lang="scss">
@import "FavoriteMarkets";
</style>